<template>
	<modal ref="modalTrasladarProductos" no-aceptar titulo="Eliminar sub-categoría de producto" icon="trash"
		adicional="Eliminar" @adicional="deleteCategoria">
		<ValidationObserver ref="form2">
			<div class="row mx-0 justify-center text-muted2 f-15 px-4 "
				:class="categoria.productos > 0 ? 'mb-5' : ''">
				<div class="col-12 px-0 mb-2 text-center">
					<p class="my-2 text-general">Esta categoría contiene <b class="text-general2"> {{
						categoria.productos }} </b> productos <span v-if="categoria.productos > 0">y no
							puede eliminarse.</span></p>
				</div>
				<div v-if="categoria.productos > 0" class="col-12 px-0 text-center">
					<p class="my-2 text-general">Para eliminarla debe trasladar los productos a otra categoría.</p>
				</div>
				<template v-if="categoria.productos > 0">
					<ValidationProvider v-slot="{ errors }" rules="required" name="categoría" tag="div"
						class="col-12 px-0 mt-2">
						<p class="text-general pl-3 f-14">
							Categoría
						</p>
						<!-- <el-select v-model="categoriaNueva" placeholder="Categoría" class="w-100">
							<el-option v-for="item in subcategorias" :key="item.id" :label="`${item.nombre}`"
								:value="item.id" />
						</el-select> -->
						<el-select v-model="categoriaNueva" class="w-100" size="small" filterable>
                            <el-option-group
                            v-for="(group,key) in subcategorias"
                            :key="key"
                            :label="group.nombre"
                            >
                                <el-option
                                v-for="(item,key2) in group.subcategorias"
                                :key="key2"
                                :label="item.nombre"
                                :value="item.id"
                                :disabled="item.disabled"
                                />
                            </el-option-group>
                        </el-select>

						<span class="text-danger f-11">{{ errors[0] }}</span>
					</ValidationProvider>
				</template>
				<div class="col-8 text-center">
					<p class="m-3 text-general">¿Desea aceptar la acción?</p>
				</div>
			</div>
		</ValidationObserver>
	</modal>
</template>

<script>
import Aws from '~/services/aws'
import Categorias from "~/services/configurar/productos";

export default {
	data() {
		return {
			subcategorias: [],
			categoria: {},
			categoriaNueva: null,
			idPadre: null
		}
	},
	methods: {
		toggle(categoria, idPadre) {
			this.categoria = categoria
			this.idPadre = idPadre
			this.getSubCategoriasOpciones()
			this.$refs.modalTrasladarProductos.toggle()
		},
		async deleteCategoria(){
			const valid = await this.$refs.form2.validate()
			if(!valid) return
			const { data } = await Categorias.deleteSubCategoria(this.categoria.id, this.categoriaNueva)
			this.$emit('remove', this.categoria.id)
			this.$refs.modalTrasladarProductos.toggle()
		},
		async getSubCategoriasOpciones(){
			const { data } = await Categorias.getSubCategoriasTodas(this.idPadre)
			this.subcategorias = data.data
		}
	}
}
</script>
